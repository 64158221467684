article.faq {
   position: relative;
	display: block;
	border: .15rem solid rgba( var(--blue), .5);
   padding: 0;
	border-radius: var(--radius);
	margin-bottom: 1rem;
	transition: var(--cubic-bez-4ms);
	overflow: hidden;
}
.faq-question {
   position: relative;
   display: grid;
   grid-template-columns: 1fr 1.3rem;
   align-items: center;
   gap: 1rem;
   padding: .5rem 1rem;
	transition: var(--cubic-bez-4ms);
}
.faq-question svg {
   height: 1.3rem;
}
.faq:hover .faq-question,
.faq[data-faq-opened="true"] .faq-question{
   background: rgb( var(--blue), .3);
}
.faq-answer {
   height: 0;
   transform: rotateX(90deg);
   transform-origin: top;
   transition: var(--cubic-bez-4ms);
}
.faq[data-faq-opened="true"] .faq-answer {
   height: auto;
   padding: 1rem;
   transform: rotateX(0);
}