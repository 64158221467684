header.logo {
   position: fixed;
   top: 0;
   width: 100%;
   padding-block: 1rem;
   z-index: 9;
}
header img {
   position: relative;
   width: 12rem;
   z-index: 10;
}
header.logo h1 {
   font-size: 3.5rem;
   line-height: 3rem;
   text-shadow: 0 0 .8rem var(--white);
   margin-top: .8rem;
}
header.logo h2 {
   font-size: 1.3rem;
   font-weight: 500;
   text-shadow: 0 0 .8rem var(--white);
}

main.slider {
	overflow: hidden;
   scroll-behavior: smooth;
}
main.slider section {
	padding: 0;
}
.slider {
   background-image: url(../imgs/bg-home.webp);
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}

nav#navbar,
nav#navbar ul,
nav#navbar ul li,
nav#navbar ul li a {
   width: 100%;
   height: 100%;
}

nav#navbar ul {
   min-height: 100vh;
   display: grid;
   grid-template-columns: repeat(6, 25vw);
}
nav#navbar ul li {
   text-align: center;
   transition: background-color var(--cubic-bez-4ms);
}
nav#navbar ul li:hover {
   background-color: var(--overlay);
}
nav#navbar ul li h3 {
	font-family: DexatersSillent, Garamond, Georgia, serif;
	font-size: 4.5rem;
	font-weight: 400;
	color: var(--black);
   transition: var(--cubic-bez-4ms);
}
nav#navbar ul li:hover h3 {
   color: var(--white);
}
nav#navbar ul li a {
   display: grid;
   place-items: center;
   grid-template-rows: 1.5fr 1fr 1fr;
   text-decoration: none;
}
nav#navbar ul li a section {
   grid-area: 2;
}
nav#navbar ul li a section p.badge {
   transform: translateY(-4rem);
   transition: all var(--cubic-bez-4ms);
   opacity: 0;
}
nav#navbar ul li a:hover section p.badge {
   transform: translateY(0);
   opacity: 1;
}

nav#navbar ul li a footer {
   grid-area: 3;
   align-self: end;
   padding-bottom: 1.5rem;
   transform: translateY(100%);
   transition: all var(--cubic-bez-4ms);
}
nav#navbar ul li a:hover footer {
   transform: translateY(0);
}
nav#navbar ul li a footer svg {
   width: 2rem;
   height: 2rem;
   color: var(--white);
}
nav#navbar ul li a footer p {
   color: var(--white);
   font-weight: 500;
}


@media (max-width: 72em) {
   main.slider {
      overflow: auto;
   }
   main.slider section {
      padding-block: 2.5rem;
   }
   .slider {
      background-image: url(../imgs/bg-home-sm.webp);
   }
   nav#navbar ul {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 16.5rem;
   }
   nav#navbar ul li a {
      grid-template-rows: 1fr;
   }
   nav#navbar ul li a section {
      grid-area: 1;
   }
   nav#navbar ul li h1 {
      color: var(--white);
   }
   nav#navbar ul li a section p.badge {
      transform: translateY(0);
      opacity: 1;
   }
   nav#navbar ul li a footer {
      display: none;
   }
}