ul.contact-info {
   font-weight: 600;
   letter-spacing: .1rem;
   max-width: fit-content;
   margin-inline: auto;
}
ul.contact-info li {
   margin-bottom: 1.5rem;
}
ul.contact-info li:last-child {
   margin-bottom: 0;
}
ul.contact-info svg {
   width: 1.2rem;
   height: 1.2rem;
   padding-right: 1rem;
}
address {
   display: inline;
   font-style: normal;
   font-weight: 500;
}

@media (max-width: 72em) {
	ul.contact-info {
		margin-top: 2.5rem;
	}
}