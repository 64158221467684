.ads li:not(:last-child) {
	max-width: 35rem;
	margin-bottom: 1.5rem;
}
.ads li a.card {
	display: block;
	padding: 1rem;
	border-radius: var(--radius);
	background: rgba(0,0,0,.1);
}
.ads a {
	text-decoration: none;
	color: var(--black);
}
.ads p {
	margin-bottom: .5rem;
}
.ads a > img,
.ads svg {
	max-width: 10rem;
}