/*
*	Author: 	Travolgi
*	Name:		Combat Camp
*	Version: 1.0.1
*	Date: 	28/06/2022
*	Last update: 	15/09/2023
*/

* {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	list-style: none;
	outline: none;
	box-sizing: border-box;
}

:root {
	--white: #fff;
	--black: #000;
	--red: 193,0,0;
	--blue: 16,103,229;
	--gradient: radial-gradient(#fff500, #dc0000 76%, #a70000);
	--green: #08b308;
	--overlay: rgba(0,0,0,.5);
	--radius: .8rem;
	--gap: 2.6rem;
	--cubic-bez-4ms: .4s cubic-bezier(.5, .05, .155, .99);
}

::-webkit-scrollbar {
	width: .7rem;
}
::-webkit-scrollbar-track {
	background: transparent; 
}
::-webkit-scrollbar-thumb {
	border-radius: var(--radius);
}
html {
	scrollbar-width: thin;
	scroll-behavior: smooth;
}

@font-face {
	font-family: DexatersSillent;
	src: url(./fonts/DexatersSillent.otf);
	font-display: swap;
}

body {
	width: 100%;
	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--black);
	background-color: var(--white);
   overflow-x: hidden;
}
header, footer {
   position: relative;
   display: block;
   text-align: center;
}
section, article, footer {
	padding-block: 2.5rem;
}
#root > footer {
	background: url(./imgs/bg-footer.webp) no-repeat top center;
	background-size: cover;
	padding-bottom: .5rem;
}

h1,
.title {
	color: var(--black);
	font-family: DexatersSillent, Garamond, Georgia, serif;
	font-size: 4.5rem;
	font-weight: 400;
}
h2,
.title2 {
	font-family: 'Montserrat', sans-serif;
	font-size: 1.6rem;
	font-weight: 600;
}
h3,
.title3 {
	font-size: 1.2rem;
}
a {
	color: rgb( var(--red) );
	text-decoration: none;
	transition: var(--cubic-bez-4ms);
	cursor: pointer;
}
a:hover {
	color: rgba( var(--red), .8);
	text-decoration: underline;
}

button {
	background-color: rgba( var(--red), .6);
	cursor: pointer;
}

img {
	width: 100%;
	border-radius: var(--radius);
}
video {
   width: 100%;
   min-height: 8rem;
   border-radius: var(--radius);
   background: var(--overlay);
}
iframe.map {
	width: 100%;
   height: 27rem;
	border-radius: var(--radius);
}

.container {
	padding: 5rem 1rem;
	max-width: 66rem;
	margin-inline: auto;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5rem;
}
.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: var(--gap);
}
.gtc-2-1 {
	grid-template-columns: 1fr .5fr;
}
.gtc-1-2 {
	grid-template-columns: .5fr 1fr;
}
.gtc-x3 {
	grid-template-columns: repeat(3, 1fr);
}
.items-start {
	align-items: start;
}

ul.list li {
	list-style: disc;
	padding-left: .5rem;
	margin-left: 2rem;
}
ul.inline li {
	display: inline-block;
	padding-right: 1rem;
	font-weight: 500;
}

.subtitle {
   font-style: italic;
   font-weight: 400;
	color: rgb( var(--red) );
}
.lh-08 {
	line-height: .8;
}
.txt-center {
	text-align: center;
}
.txt-left {
	text-align: left;
}
.txt-red {
	color: rgb( var(--red) );
}
.txt-blue {
	color: rgb( var(--blue) );
}
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-2 {
	margin-bottom: 2rem;
}
.p-0 {
	padding: 0;
}
.pb-2 {
	padding-bottom: 2rem;
}

.badge {
	display: inline-block;
   border-radius: 1.5rem;
   padding: .4rem 1.6rem;
   font-style: italic;
   font-weight: 300;
   color: var(--white);
   background-color: rgba( var(--red), .6);
}
.badge-icon {
	display: inline-block;
   width: 5rem;
   aspect-ratio: 1;
	padding: 1rem;
   border-radius: 50%;
   background: var(--gradient);
}
.badge-icon > svg {
   width: 2.5rem;
   height: 2rem;
   padding-top: .5rem;
}

a.badge {
	border: .15rem solid rgba( var(--red), .6);
	transition: var(--cubic-bez-4ms);
	text-transform: uppercase;
	font-weight: 700;
}
a.badge:hover {
   color: var(--black);
   background: var(--gradient);
	border: .15rem solid rgb( var(--red) );
	text-decoration: none;
}

.top-shadow {
   position: absolute;
   inset: 0;
   width: 100%;
   height: 8rem;
   background-image: linear-gradient(var(--overlay), transparent);
}

.flogo img {
	width: 16rem;
	margin-bottom: .8rem;
}
.flogo h3 {
	font-weight: 500;
	margin-bottom: 1.8rem;
}
.tlogo {
	width: 7rem;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
}

@media (max-width: 72em) {
	article {
		padding-block: 0;
	}
	ul.inline {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
	.grid, .gtc-2-1, .gtc-1-2, .gtc-x3 {
		grid-template-columns: 1fr;
	}
	#root > footer {
      background-image: url(./imgs/bg-footer-sm.webp);
   }
}

/* Cookie Fix */
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a,
#CybotCookiebotDialogDetailBodyContentTextAbout a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CookiebotWidget .CookiebotWidget-consent-details button,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved[data-consent=necessary] svg { color: rgb(var(--blue)) !important; }
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg { fill: rgb(var(--blue)) !important; }
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible+span,
#CybotCookiebotDialog :focus-visible { outline: 0 !important; }
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
	border-bottom: 2px solid rgb(var(--blue)) !important;
	color: rgb(var(--blue)) !important;
}
#CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change { background-color: rgb(var(--blue)) !important; }
#CybotCookiebotDialogTabContent input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider { background-color: #D6D6D6 !important; }
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change { 
	border: 0 !important;
	box-shadow: none !important;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {	border-radius: 2rem !important; }
@media screen and (max-width: 600px) {
	#CookiebotWidget:not(.CookiebotWidget-inactive) {
		bottom: 20px !important;
		left: 20px !important;
	}
}
