form {
   margin-top: 3rem;
}
.req {
   display: block;
   margin-bottom: .5rem;
   text-align: center;
}
label {
   position: absolute;
   display: block;
}
label > svg {
   height: 1.3rem !important;
   padding-top: 1rem;
   padding-left: 1rem;
   color: rgba( var(--red), .6);
}
label[for="agree"] {
   display: inline;
}

input, textarea {
   width: 100%;
   padding: 1rem 1.5rem 1rem 3rem;
   margin-bottom: 1rem;
   border: .15rem solid var(--black);
	border-radius: var(--radius);
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 600;
   letter-spacing: .1rem;
   color: var(--black);
   transition: var(--cubic-bez-4ms);
}
input:hover, textarea:hover {
   border-bottom-color: rgba( var(--red), .6);
}
input::placeholder {
   color: var(--overlay);
}
input[type="checkbox"]{
   width: min-content;
   margin-right: .5em;   
}

.sent, .error {
   text-align: center;
   margin-bottom: 1rem;
   color: var(--green);
   transition: var(--cubic-bez-4ms);
}
.error {
   color: rgb( var(--red) ); 
}

button[type="submit"] {
   aspect-ratio: 1;
   display: block;
   margin: auto;
   padding: 1.2rem;
   margin-top: 1.5rem;
   border-radius: 50%;
   border: .15rem solid rgb( var(--red) );
   background: transparent;
   color: rgb( var(--red) );
   transition: var(--cubic-bez-4ms);
}

button[type="submit"] svg {
   height: 1.6rem;
}

button[type="submit"]:hover {
   color: var(--black);
   background: var(--gradient);
}