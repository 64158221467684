.event-grid {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: var(--gap);
   row-gap: 4rem;
   padding-block: 2rem;
}

.event-grid > a:hover {
   transform: scale(1.1);
   text-decoration: none;
}

.event-grid article {
   padding-block: 0;
   text-align: left;
}

.event-grid article:not(.review) > h2 {
	font-family: DexatersSillent, Garamond, Georgia, serif;
   font-size: 3.4rem;
   font-weight: 400;
   line-height: .8;
   margin-block: 1rem .6rem;
   color: rgb( var(--red) );
}
.event-grid article > h3 {
   color: rgb( var(--blue) );
}

ul.athlete-name li {
   font-family: DexatersSillent, Garamond, Georgia, serif;
   font-size: 3.35rem;
   font-weight: 400;
   line-height: 1;
   color: rgb( var(--red) );
}

@media (max-width: 72em) {
	.event-grid {
		grid-template-columns: 1fr;
	}
}