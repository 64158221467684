section.cta {
   padding: 3rem 2rem;
   margin-bottom: 2rem;
   border-radius: var(--radius);
   text-align: center;
   color: var(--white);
   background: url(../imgs/bg-cta.webp);
   background-position: right center;
   background-repeat: no-repeat;
   background-size: cover;
}

section.cta > h2 {
	font-family: DexatersSillent, Garamond, Georgia, serif;
   font-size: 3.8rem;
   font-weight: 400;
   line-height: .8;
   margin-bottom: 1rem;
   color: rgb( var(--red) );
}
section.cta > p {
   max-width: 45rem;
   margin-inline: auto;
   margin-bottom: 2.6rem;
}