.team-vertical {
   display: grid;
   grid-template-columns: 1fr min-content 1.2rem;
   align-items: start;
}

.team-vertical h2 {
	font-family: DexatersSillent, Garamond, Georgia, serif;
   font-size: 3.5rem;
   font-weight: 400;
   color: rgb( var(--blue) );
}
.team-vertical h3, .team-vertical h2 {
   writing-mode: vertical-rl;
}

.sponsor {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: var(--gap);
}

@media (max-width: 72em) {
   .sponsor {
      grid-template-columns: repeat(2, 1fr);
   }
}