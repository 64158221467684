.gallery-container {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   padding: 0;
}
.gallery-container > img {
   aspect-ratio: 1;
   object-fit: cover;
   border-radius: 0;
}
.gallery-container > img.span2 {
   grid-column: span 2;
   aspect-ratio: 2 / 1;
}
.gallery-container > img:hover {
   filter: brightness(.5);
   transition: var(--cubic-bez-4ms);
}

@media (max-width: 72em) {
   .gallery-container {
      grid-template-columns: 1fr;
   }
   .gallery-container > img.span2 {
      grid-column: unset;
   }
}
